import React from "react";
import loadable from "@loadable/component";
import { graphql } from "gatsby";

const Layout = loadable(() => import("@common/Layout/Layout"));
const CookiePolicy = loadable(() => import("@components/CookiePolicy"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));

const CookiePolicyPage = ({ path, data }) => (
    <>
        <SEO
            title="Optimize Your Web Experience: Discover How Cookies Enhance impressit.io"
            titleTemplate="Impressit"
            SEOData={data.allStrapiSeoDescriptions?.nodes}
            description="Discover how Impressit.io uses cookies to personalize content, ads, and analyze site traffic, enhancing your digital experience"
            path={path}
        />
        <Layout path={path}>
            <CookiePolicy />
        </Layout>
    </>
);

export default CookiePolicyPage;

export const cookiePolicyPageQuery = graphql`
    query cookiePolicyPageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
